import * as React from 'react';

import { Page, flexgutter } from '../components/common';

import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from '@emotion/styled';

type Props = {
  path: string;
};

const Privacy = styled.div`
  ${flexgutter}
`;

const PrivacyPolicyPage: React.FC<Props> = ({ path }: Props) => (
  <Layout path={path}>
    <SEO title="Privacy Policy" />
    <Helmet />
    <Privacy>
      <Page>
        <div>
          <h3>Privacy Policy</h3>
          <p>
            Silofit Enterprises (“Silofit”, “we” or “us”) recognizes the need to
            protect the privacy of the personal information you provide to
            Silofit with respect to your access and use of Silofit’s website
            (https://Silofit.com) (the Website) or through Silofit’s mobile and
            web applications (the Website and such mobile and web applications
            being collectively referred to as the Applications).
          </p>
          <p>
            Therefore, we have adopted this privacy policy (the Privacy Policy),
            which sets forth, among other things, the type of information that
            will be collected, the purpose and use of the collected information,
            and your rights with regard to the collected information. This
            Privacy Policy governs how we collect and use your personal
            information wherever you use our Applications. By accessing the
            Applications, you are consenting to the collection and the use of
            your information by Silofit, but only to the extent described
            herein. Should you wish to revoke your consent, you may do so by
            contacting Silofit, information on which is set out at Section 4
            below.
          </p>
          <p>
            Silofit may make modifications, deletions and/or additions to this
            Privacy Policy (Changes) at any time. Changes will be effective: (i)
            thirty (30) days after Silofit provides notice of the Changes,
            whether such notice is provided through the Applications user
            interface, is sent to the email address associated with your account
            or otherwise; or (ii) when you opt-in or otherwise expressly agree
            to the Changes or a version of this Privacy Policy incorporating the
            Changes, whichever comes first.
            <br />
            <br />
            <strong>1. Protection of Your Information</strong>
            <br />
            We are accountable for the information in our custody. Therefore,
            when we collect or use your information, we will utilize
            commercially reasonable safeguards to ensure its protection. It
            should be noted that no security procedure is currently 100%
            effective. Should any breach of your Personal Information occur, we
            will inform you as soon as reasonably possible, as required by
            applicable law.
            <br />
            <br />
            <strong>2. Type and Purpose of Collection</strong>
            <br />
            We collect information at various points in the Applications to
            facilitate its use by our customers. Specifically, two types of
            information are collected:
          </p>
          <p>
            A. Non-Personal Information: Upon accessing the Applications,
            certain non-personal information will be automatically collected
            without your knowledge or consent, such as your IP address, location
            data (which is anonymous and where you log in through the
            Applications you will be asked if you consent to the Applications
            accessing your location, which can be updated at any time in your
            device settings) and the referring website (“Non-Personal
            Information”). We use Non-Personal Information to examine our
            traffic and to view how our customers use the Applications. This
            type of information will not allow you to be personally identified
            although we might be able to associate it with your account. For
            example, we use “cookies”, which contain only certain statistical
            information. For more information on cookies please see Section 11
            below.
          </p>
          <p>
            B. Identifying Personal Information: To utilize some portions of the
            Applications, you must first provide personal information that will
            allow you to be identified (Personal Information). This type of
            information will not be collected without your consent. The purposes
            of the collection of Personal Information are the following:
          </p>
          <p>i. To fulfill your order;</p>
          <p>ii. To send you an order confirmation;</p>
          <p>iii. To administer your account;</p>
          <p>
            iv. To send you push notifications or SMS messages when you are
            using the Applications in relation you use of the services we
            provide, for example, to provide a code in order to access a
            location;
          </p>
          <p>
            v. To send you our newsletter provided you have not unsubscribed.
            You may opt out of such newsletter through using the unsubscribe
            link or by contacting us at info@Silofit.com;
          </p>
          <p>vi. To establish a relationship with you;</p>
          <p>
            vii. To facilitate your service with our affiliated service
            providers;
          </p>
          <p>viii. To anticipate and resolve problems with your service;</p>
          <p>
            ix. To understand your needs and desires vis-à-vis the Applications;
          </p>
          <p>
            x. To update you on changes to our services or products, including
            new promotions provided that you have not unsubscribed. You may opt
            out of such notifications through the unsubscribe link or by
            contacting us at info@Silofit.com.
          </p>
          <p>
            We expressly acknowledge that we will not use your Personal
            Information for any other purposes without your consent. Further, we
            will only collect Personal Information to the extent necessary for
            the abovementioned purposes. The Personal Information we collect
            will vary depending on how you are using the Applications, but may
            include, without limitation: (i) your name, mailing address, phone
            number and email address; (ii) credit card information and charge
            history; (iii) unique identifiers such as user name, account number
            and password; (iv) preferences information such as product or
            content interests, communication or marketing preferences; (v)
            device information and data; (vi) reservation history and coupon
            usage history; and (vii) Google, Twitter and Facebook identity.
          </p>
          <p>
            Additionally, Personal Information may be used and disclosed to the
            extent Silofit may deem reasonably necessary to enforce the terms of
            any agreement between you and Silofit, or to protect the rights,
            property or safety of any person or entity.
            <br />
            <br />
            <strong>3. Right to Examine Information</strong>
            <br />
            You have the right to examine any of your Personal Information that
            we collect. Should you wish to examine such information, please send
            us a written request to info@Silofit.com. We reserve the right to
            charge you a reasonable administrative fee to access your
            information, as permitted by applicable law. In certain cases, we
            may not be able to provide you with access to all of your Personal
            Information (ex: if the information also pertains to the Personal
            Information of another user).
            <br />
            <br />
            <strong>4. Withdrawal of Consent</strong>
            <br />
            You may withdraw your consent to the collection of Personal
            Information at any time by sending a written request to Silofit
            (please see below). Upon receiving notice that you have revoked your
            consent, we will stop using your Personal Information within a
            reasonable time, which will vary depending on what information we
            have collected and for what purpose. Please note that we will send
            you an email confirmation upon receipt of your request. Therefore,
            if you do not receive a confirmation email, please contact us again
            with your request. If you do choose to withdraw such consent, your
            experience on the Applications may be diminished, or your ability to
            choose some of the options on the Applications may be limited.
          </p>
          <p>
            Silofit Enterprises
            <br />
            415 Rue Le Moyne
            <br />
            Suite 301
            <br />
            Montreal, Canada
            <br />
            QC H2Y2Y5
            <br />
            Contact email: info@Silofit.com
            <br />
            <br />
            <strong>5. Sharing Information</strong>
            <br />
            We will not sell, rent or disclose to outside parties the
            information we collect and save except that we may share the
            collected information with other parties as follows:
          </p>
          <p>
            a. Affiliated Service Providers: We have agreements with various
            affiliated service providers to facilitate the functioning of the
            Applications, with whom we may share the information we have
            collected. For example, we may share your credit card information
            with the credit card service provider to process your purchase. All
            administrative service providers that we use are required to have
            the same level of privacy protection as we have, and therefore we
            expect that your information will be handled with the same level of
            care that we employ. Additionally, for example, we may use analytic
            services
          </p>
          <p>
            b. Where required by law: We may share the collected information
            where required by law, specifically in response to a demand from
            government authorities where such demand meets the legal
            requirements.
          </p>
          <p>
            c. Statistical Analysis: We may share Non-Personal Information and
            aggregated information with third parties, including but not limited
            to for advertising or marketing purposes. No Personal Information
            will be shared in this manner.
          </p>
          <p>
            d. Transactions: In connection with, or during negotiations of, any
            merger, sale of company assets, financing or acquisition, or in any
            other situation where Personal Information may be disclosed or
            transferred as one of our business assets.
          </p>
          <p>
            e. Silofit offices and operations: We may share Personal Information
            and Non-Personal Information with our group companies or with our
            offices and operations based outside your local country in order to
            provide you with our services and for our own operational processes.
            <br />
            <br />
            <strong>6. External Links</strong>
            <br />
            The Application contains links and references to other websites. We
            are not responsible for the collection, use and disclosure of
            information, or the privacy practices of such websites, and we
            expressly disclaim any liability relating thereto.
            <br />
            <br />
            <strong>7. International Transfer</strong>
            <br />
            Both Personal Information and Non-Personal Information you submit
            via the Applications is sent to our group companies and will
            processed in Canada and the USA and stored on secure servers located
            in the USA. Such information may also be transferred by us to our
            offices and third parties mentioned in the circumstances (see
            Section 5 above). The countries concerned may not have similar data
            protection laws to your country. Where we transfer your information
            out of your jurisdiction we will take reasonable steps to ensure
            that your privacy rights continue to be protected. By submitting
            your information to the Applications, you agree to this storing,
            processing and transfer.
            <br />
            <br />
            <strong>8. Payment processing</strong>
            <br />
            Payment details you provide will be encrypted using secure sockets
            layer (SSL) technology before they are submitted over the internet.
            Payments made on the Applications are made through our payment
            gateway provider, Stripe. You will be providing credit or debit card
            information directly to Stripe which operates a secure server to
            process payment details, encrypting your credit/debit card
            information and authorising payment. Information which you supply to
            Stripe is not within our control and is subject to Stripe's own
            privacy policy and terms and conditions.
            <br />
            <br />
            <strong>9. Terms of Service</strong>
            <br />
            This Privacy Policy is incorporated into and forms part of the Terms
            of Service, which outlines the terms and conditions you agree to
            when accessing and using the Applications, and which can be found
            here: https://Silofit.com
            <br />
            <br />
            <strong>10. Persons Under 18</strong>
            <br />
            The Applications are not marketed toward persons under the age of
            18. If Silofit discovers that it has inadvertently collected
            Personal Information about individuals under the age of 18, it will
            promptly delete such information.
            <br />
            <br />
            <strong>11. Cookies and similar technologies</strong>
            <br />
            When you interact with the Applications, we try to make that
            experience simple and meaningful. When you use our Applications, our
            web server sends a cookie to your computer or mobile device (as the
            case may be). Cookies are small pieces of information which are
            issued to your computer or mobile device (as the case may be) when
            you visit a website or access or use a mobile application and which
            store and sometimes track information about your use of a website or
            application (as the case may be). A number of cookies we use last
            only for the duration of your web session or Applications session
            and expire when you close your browser or exit the Applications.
            Other cookies are used to remember you when you return to the
            Applications and will last for longer.
          </p>
          <p>
            Some of the cookies used by the Applications are set by us, and some
            are set by third parties who are delivering services on our behalf.
          </p>
          <p>
            Most web and mobile device browsers automatically accept cookies
            but, if you prefer, you can change your browser to prevent that or
            to notify you each time a cookie is set. You can also learn more
            about cookies by visiting www.allaboutcookies.org which includes
            additional useful information on cookies and how to block cookies
            using different types of browser or mobile device. Please note,
            however, that by blocking or deleting cookies used on the
            Applications, you may not be able to take full advantage of the
            Applications.
          </p>
          <p>
            The Applications use cookies and similar technologies to cookies to
            collect information about your access and use of the Applications.
            You can also update your privacy settings on your device by setting
            the ‘Limit Ad Tracking’ and Diagnostics and Usage setting property
            located in the settings screen of your Apple iPhone or iPad, or by
            resetting your Android ID through apps that are available in the
            Play Store. You can also stop information collection by uninstalling
            the Applications on your device and you can use the standard
            uninstall process available as part of your device for this purpose.
            <br />
            <br />
            <strong>12. Social Media</strong>
            <br />
            You can log-on to your account with us by using social media
            plug-ins from social media platforms such as Twitter and Facebook.
            If you choose to log in via a social media platform, our
            Applications will connect to the servers of the social media
            platform. We will receive your basic profile information as directed
            by the relevant social media platform when you log in. You will also
            have the option to share features of our Applications with the
            relevant social media platform as you wish. Visit the privacy policy
            of the relevant social media platform provider to learn more about
            your rights and choices.
          </p>
        </div>
      </Page>
    </Privacy>
  </Layout>
);

PrivacyPolicyPage.defaultProps = {
  path: '/privacy-policy/',
};

export default PrivacyPolicyPage;
